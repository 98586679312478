/* eslint-disable prettier/prettier */
import React from 'react';
import Layout from '@/components/Layout';
import Gallery from '@browniebroke/gatsby-image-gallery';

import { ReferenceGroup } from '@/types';

import { graphql, PageProps } from 'gatsby';
import categories from '@/content/reference-categories.json';

import './reference.module.css';
import Divider from '@/components/divider';
import SEO from '@/components/Seo';

type PageData = {
  images: {
    edges: Array<{
      node: {
        name: string;
        ext: string;
        childImageSharp: any;
      };
    }>;
  };
};

export default function ReferenceTemplate({
  pageContext,
  data,
}: PageProps<PageData, ReferenceGroup>) {
  const { references, uri } = pageContext;
  return (
    <>
      <SEO
        title={uri}
        description=""
        keywords={references.map((ref) => ref.title)}
      />
      <Layout
        activeMenu={{ uri: `/referenzen/${uri}`, label: uri }}
        baseLink="/referenzen"
        menuItems={categories.links.map((cat) => ({
          uri: `/referenzen/${cat.uri}`,
          label: cat.label,
        }))}
      >
        {references.map((reference, idx) => (
          <div className="reference" key={reference.title}>
            <div className="content-padding">
              <h2>{reference.title}</h2>
            </div>
            <Divider fullWidth />
            <div className="content-padding" style={{ marginBottom: `38px` }}>
              <Gallery
                images={data.images.edges
                  .filter(({ node }) =>
                    reference.images.includes(`${node.name}${node.ext}`),
                  )
                  .map(({ node }) => ({
                    ...node.childImageSharp,
                  }))}
                colWidth={100}
                mdColWidth={100 / 4}
              />
            </div>
            {!!reference.description?.trim() && <Divider />}
            <div className="content-padding">
              <p>{reference.description}</p>
            </div>
            {idx !== references.length - 1 && <Divider />}
          </div>
        ))}
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query ReferenceQuery($images: [String]) {
    images: allFile(
      filter: { relativePath: { in: $images } }
      sort: { fields: name }
    ) {
      edges {
        node {
          name
          ext
          childImageSharp {
            thumb: gatsbyImageData(
              width: 400
              height: 400
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
